import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import { User } from '@app/objects/User';

const params = {
	fallback: <LogoLoaderPage />,
};

const DashboardLayout = loadable(() => loadableDelay(/* webpackChunkName: "DashboardLayout" */
	import('@app/components/Layouts/DashboardLayout/DashboardLayout'),
), params);

interface Props {
	component: any;
	redirectPath?: string;
	title?: string;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', title, ...rest
}) => {
	const { getUser } = useApplicationContext();
	const user = getUser<User>();
	return (user ? <DashboardLayout>
		{title && <Helmet>
			<title>{title}</title>
		</Helmet>}
		<ErrorBoundaryWithSentry>
			<RequestProvider>
				{Component ? <Component /> : <Outlet />}
			</RequestProvider>
		</ErrorBoundaryWithSentry>
	</DashboardLayout> : <Navigate to={redirectPath} />);
};

export default DashboardRoute;
