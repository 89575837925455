import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { MenuItem as BaseMenuItem } from '@common/react/objects/MenuItem';
import { Nullable } from '@common/typescript/objects/Nullable';

import { CountersState } from '@app/store/Counters';
import { User } from '@app/objects/User';

import '@app/scss/components/menu.scss';

interface MenuItem extends BaseMenuItem {
	node?: React.ReactNode;
	icon?: React.ReactNode;
}

interface MenuItemProps extends Omit<MenuProps, 'items' | 'className'> {
	item: MenuItem;
	counters?: CountersState;
}

interface MenuProps {
	items: Array<MenuItem>;
	counters?: CountersState;
	className?: string;
	withChildren?: boolean;
	basePath?: string;
	pathKey?: string;
	defaultOpen?: boolean;
	user?: Nullable<User>;
}

const Item: React.FC<MenuItemProps> = ({
	item,
	counters,
	withChildren,
	basePath,
	pathKey,
	defaultOpen,
	user,
}) => {
	const [open, setOpen] = React.useState<boolean>(defaultOpen || ((typeof item.isOpen !== 'undefined') ? item.isOpen : false));

	const condition = withChildren && item.children && item.children.list && item.children.list.length > 0;
	const path = item[pathKey || 'path'];
	const { exact = false } = item;

	// tslint:disable-next-line:max-line-length
	const className = `menu-component__item ${open && condition
		? 'menu-component__item_open' : ''} ${condition
		? 'menu-component__item_with-children' : ''} ${item.className || ''}`;

	const requiredBadge = React.useMemo(() => {
		return {
			'/chats': {
				condition: !!user && user.unviewedMessagesCount > 0,
				value: user?.unviewedMessagesCount,
				title: 'Unviewed messages',
			},
			'/document-list': {
				condition: !!counters && counters.notViewedDocumentsCount > 0,
				value: counters?.notViewedDocumentsCount,
				title: 'Not read documents',
			},
			'/questionnaires': {
				condition: !!counters && counters.notAnsweredDiseasesCount > 0,
				value: counters?.notAnsweredDiseasesCount,
				title: 'Not answered questionnaires',
			},
			'/consent-forms': {
				condition: !!counters && counters.notAnsweredConsentsCount > 0,
				value: counters?.notAnsweredConsentsCount,
				title: 'Not signed consent forms',
			},
			'/instructions': {
				condition: !!counters && counters.notReadInstructionsCount > 0,
				value: counters?.notReadInstructionsCount,
				title: 'Not read instructions',
			},
			'/prescriptions-list': {
				condition: !!counters && counters.notViewedPrescriptionsCount > 0,
				value: counters?.notViewedPrescriptionsCount,
				title: 'Not read prescriptions',
			},
			'/bills': {
				condition: !!counters && counters.notPaidBillItemsCount > 0,
				value: counters?.notPaidBillItemsCount,
				title: 'Not paid bills count',
			},
		};
	}, [user, counters]);

	const totalBadge = React.useMemo(() => {
		return {
			'/document-list': {
				condition: !!counters && counters.totalDocumentsCount > 0,
				value: counters?.totalDocumentsCount,
				title: 'Total documents count',
			},
			'/questionnaires': {
				condition: !!counters && counters.totalDiseasesCount > 0,
				value: counters?.totalDiseasesCount,
				title: 'Total questionnaires count',
			},
			'/consent-forms': {
				condition: !!counters && counters.totalConsentsCount > 0,
				value: counters?.totalConsentsCount,
				title: 'Total consent forms count',
			},
			'/instructions': {
				condition: !!counters && counters.totalInstructionsCount > 0,
				value: counters?.totalInstructionsCount,
				title: 'Total instructions count',
			},
			'/prescriptions-list': {
				condition: !!counters && counters.totalPrescriptionsCount > 0,
				value: counters?.totalPrescriptionsCount,
				title: 'Total prescriptions count',
			},
			'/bills': {
				condition: !!counters && counters.totalBillItemsCount > 0,
				value: counters?.totalBillItemsCount,
				title: 'Total bills count',
			},
		};
	}, [counters]);

	const toggleMenu = () => {
		setOpen(!open);
	};

	if (item.node) {
		return <>{item.node}</>;
	}

	const icon = item.icon && <i className={`fa fa-${item.icon} mr10 main-section-icon`} />;

	return (
		<li className={className}>
			{path ? item.externalLink
				? <a className="menu-component__item-link" href={item.path} target="_blank" rel="noreferrer">
					{icon}
					{item.name}
				</a>
				: (
					<NavLink
						end={exact}
						to={basePath + path}
						className={({ isActive }) => (isActive ? 'menu-component__item-link menu-component__item-link_active'
							: 'menu-component__item-link')}
					>
						{icon}
						<span className={`menu-component__item-name${requiredBadge[item.path]?.condition
							? totalBadge[item.path]?.condition
								? ' two-badge'
								: ' one-badge'
							: totalBadge[item.path]?.condition
								? ' one-badge'
								: ''
						}`}
						>
							{item.name}
						</span>
						{requiredBadge[item.path]?.condition
							? <span className="badge menu-badge pull-right ml10" title={requiredBadge[item.path].title}>
								{requiredBadge[item.path].value}
							</span>
							: null
						}
						{totalBadge[item.path]?.condition
							? <span className="badge menu-badge-total pull-right" title={totalBadge[item.path].title}>
								{totalBadge[item.path].value}
							</span>
							: null
						}
					</NavLink>
				)
				: <span className="menu-component__item-link" onClick={toggleMenu} style={{ cursor: 'pointer' }}>
					{icon}
					{item.name}
				</span>
			}
			{condition
				&& <>
					<i className={`icons menu-component__item-toggle ${open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`} onClick={toggleMenu} />
					<div className={`menu-component__item-children-container ${open ? 'isOpen' : ''}`}>
						<ul className="menu-component__children">
							{item.children && item.children.list.map((child, index) =>
								<Item
									item={child}
									key={`${child.path} ${child.name}`}
									withChildren={withChildren}
									basePath={child.basePath || basePath || ''}
									pathKey={pathKey}
								/>)}
						</ul>
					</div>
				</>
			}
		</li>
	);
};

export const Menu: React.FC<MenuProps> = ({
	className = '',
	items,
	withChildren = false,
	basePath,
	pathKey,
	defaultOpen,
	counters,
	user,
}) => {
	const menuItems = items || [];

	return <ul className={`menu-component ${className}`}>
		{menuItems.map((item, index) =>
			<Item
				defaultOpen={defaultOpen}
				key={`${item.path} ${item.name}`}
				item={item}
				withChildren={item.children ? true : withChildren}
				basePath={item.basePath || basePath || ''}
				pathKey={pathKey}
				counters={counters}
				user={user}
			/>)}
	</ul>;
};
