import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import useShoppingCart from '@app/hooks/useShoppingCart';

const BuyNow: React.FC<{ specialForStore, className? }> = (props) => {
	const navigate = useNavigate();
	const { getItem, addCartItem } = useShoppingCart();
	const {
		specialForStore,
		className = '',
	} = props;
	const storedItem = getItem(specialForStore?.id);

	return <a
		onClick={(e) => {
			e.preventDefault();
			if (!storedItem) {
				addCartItem(specialForStore, 1);
			}
			navigate('/ordering');
		}}
		className={`special-buy-now-button ${className}`}
	>
		<span className="special-buy-now add-to-cart-btn">
			Buy now
		</span>
	</a>;
};

export default BuyNow;
