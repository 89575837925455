import React from 'react';

import { Outlet } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';

import { MainLayout } from '@app/components/Layouts/MainLayout';

import Footer from '@app/components/UI/Footer/Footer';

const RouteWithFooter: React.FC<{ component? }> = ({ component: Component }) => (
	<MainLayout>
		<ErrorBoundaryWithSentry>
			{Component ? <Component /> : <Outlet />}
			<Footer />
		</ErrorBoundaryWithSentry>
	</MainLayout>
);

export default RouteWithFooter;
