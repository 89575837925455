import React from 'react';

// @eslint-disable-next-line
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { Location } from 'history';
import Modal, { ModalFuncProps } from 'antd/lib/modal';

export interface LeaveConfirmationModalProps {
	handleBlockedNavigation: (nextLocation: Location) => boolean;
	when?: boolean;
	onOk: (leaveLocation: () => void) => void;
	message?: string;
	cancelText?: string;
	okText?: string;
	onCancel?: () => void;
	confirmProps?: Omit<ModalFuncProps, 'onOk' | 'onCancel' | 'content'>;
}

const LeaveConfirmationModal: React.FC<LeaveConfirmationModalProps> = ({
	when, handleBlockedNavigation, onOk, cancelText = 'No', okText = 'Yes', onCancel, ...rest
}) => {
	const { message = 'Leave the page?' } = rest;
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (!when) return false;
		if (`${currentLocation.pathname}${currentLocation.search}` === `${nextLocation.pathname}${nextLocation.search}`) {
			return false;
		}
		return !handleBlockedNavigation(nextLocation);
	});

	React.useEffect(() => {
		if (blocker.state === 'blocked' && !when) {
			blocker.reset();
		}
		if (blocker.state === 'blocked' && when) {
			Modal.confirm({
				onCancel: () => {
					blocker.proceed?.();
					onCancel && onCancel();
				},
				onOk: () => {
					const handleLeave = () => {
						blocker.proceed?.();
					};
					onOk(handleLeave);
				},
				content: message,
				...rest.confirmProps,
				cancelText: 'No',
				okText: 'Yes',
			});
		}
	}, [when, blocker]);

	return (
		<></>
	);
};

export default LeaveConfirmationModal;
