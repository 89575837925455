import * as React from 'react';

import { showDate } from '@common/react/utils/timeUtils';

import { CompanyFeature, Feature } from '@commonTuna/react/objects/CompanyFeature';

export const dateTimeFormatString = 'MM/DD/YYYY h:mm A';
export const dateFormatString = 'MM/DD/YYYY';

export const dateToUtcString = (date, format): string => {
	if (date === undefined || date === null) {
		return '';
	}
	return showDate(date, typeof format === 'string' && format.includes('h:mm'), true);
};

export const longZipFormat = (zip: string | null | undefined): string => (zip ? zip.replace(/^(\d{5})(\d{4})$/, '$1 - $2') : '');

export const phoneWithoutAreaCodeFormat = (phone: string): string => (phone
	? phone.replace(/^(?:\+[1-9])?(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3') : '');

export enum DashboardTimeInterval {
	All = 0,
	Year = 1,
	Month = 2,
	Week = 3,
	Day = 4,
	Quarter = 5
}

export const intervals = [
	{ caption: 'Day', interval: DashboardTimeInterval.Day },
	{ caption: 'Week', interval: DashboardTimeInterval.Week },
	{ caption: 'Month', interval: DashboardTimeInterval.Month },
	{ caption: 'Quarter', interval: DashboardTimeInterval.Quarter },
	{ caption: 'Year', interval: DashboardTimeInterval.Year },
	{ caption: 'All', interval: DashboardTimeInterval.All },
];

export const TimePeriodNames = {
	[DashboardTimeInterval.Year]: 'YTD',
	[DashboardTimeInterval.Quarter]: 'QTD',
	[DashboardTimeInterval.Month]: 'MTD',
	[DashboardTimeInterval.Week]: 'WTD',
};

export const tripleVarGroup = [
	{ caption: 'Yes', value: true },
	{ caption: 'No', value: false },
	{ caption: 'All', value: null },
];

export const getMoneyString = (value: number, currencyPrefix = '$') => {
	return `${currencyPrefix}${value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const hasFeature = (features: Array<CompanyFeature>, feature: Feature): boolean => {
	return features.some((c) => c.feature === feature);
};

export const hasFeatures = (companyFeatures: Array<CompanyFeature>, features: Array<Feature>): boolean => {
	return features.every((f) => hasFeature(companyFeatures, f));
};
