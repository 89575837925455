import * as React from 'react';
import { useLocation } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { ErrorBoundary } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

class ErrorBoundaryWithSentryBase extends ErrorBoundary {
	componentDidCatch(error, errorInfo) {
		super.componentDidCatch && super.componentDidCatch(error, errorInfo);
		Sentry.captureException(error);
	}
}

export const ErrorBoundaryWithSentry: React.FC = ({ children }) => {
	const location = useLocation();
	return <ErrorBoundaryWithSentryBase
		location={location}
	>
		{children}
	</ErrorBoundaryWithSentryBase>;
};

export default ErrorBoundaryWithSentry;
