import React from 'react';

import Tag from 'antd/lib/tag';
import loadable from '@loadable/component';

import { ChatMessage, ChatPlugins } from '@common/react/components/Chat/Chat';
import { processLink } from '@common/react/components/UI/LinkPreview/LinkPreview';
import { transformLinks } from '@common/react/components/Chat/ChatUtils';
import { File } from '@common/typescript/objects/FileInterface';
import { imageExtensions } from '@common/react/components/Chat/Chats';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

const getFilesLayout = (files: Array<File>, onClick?: (e, file: File) => void): JSX.Element | null => {
	const filteredFiles = files.filter((f) => f.file.name !== 'video_message.webm' && f.file.name !== 'voice_message.mp3');

	if (filteredFiles.length <= 0) {
		return null;
	}

	const { state: { basePath = '/' } } = useChatSettingsProviderContext();

	return <>
		<div className="chat-uploaded-files" style={{ flexWrap: 'wrap' }}>
			{filteredFiles
				.map((f) => (
					<a key={f.file.id} href={`${basePath}${f.file.src}`} target="_blank" download rel="noreferrer">
						<Tag className="chat-file-tag">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div className="chat-file-tag_name__wrapper">
									<i className="fa fa-file-o" />
&nbsp;&nbsp;
									<span
										title={f.file.name}
										className="chat-file-tag_name"
									>
										{f.file.name}
									</span>
&nbsp;&nbsp;
									<i className="fa fa-download" />
&nbsp;&nbsp;
								</div>
								{imageExtensions.includes(f.file.extension?.toLowerCase()) && <div>
									<img
										onClick={(e) => onClick && onClick(e, f)}
										className="chat-file-tag-image"
										src={`${basePath}${f.file.src}`}
										alt={f.file.name}
									/>
								</div>}
							</div>
						</Tag>
					</a>
				))
			}
			<div className="chat-uploaded-files__more">
				+
				{filteredFiles.length - 2}
			</div>
		</div>
	</>;
};

interface RegularChatMessage {
	message: ChatMessage;
	onImageClick?: any;
	contacts: Array<any>;
	handleLinks?: boolean;
}

const RegularChatMessage: React.FC<RegularChatMessage> = ({
	message,
	onImageClick,
	contacts,
	handleLinks = true,
	children,
}) => {
	const context = useChatSettingsProviderContext();
	const plugins = context?.state?.plugins;
	const [previewMatches, setPreviewMatches] = React.useState<Array<string>>([]);

	React.useEffect(() => {
		handleLinks && handleLinkProcess();
	}, [message, message.text]);

	const handleLinkProcess = React.useCallback(() => {
		const processResult = processLink(message.text || '');
		setPreviewMatches(processResult);
	}, [message, message.text]);

	return (
		<>
			{message.text?.length > 0 ? (
				<TextChatMessage
					updateAfterTransform={transformLinks}
					text={message.text}
					contacts={contacts}
					className="chat-message-list-component__item-content"
				/>
			) : null}
			{previewMatches && plugins?.[ChatPlugins.LinkPreview]?.listComponent?.({ links: previewMatches, linkPreviewId: `message-${message.id}` })}
			{message.files.length ? getFilesLayout(message.files, onImageClick) : null}
		</>
	);
};

export default RegularChatMessage;
