import React from 'react';
import { useLocation } from 'react-router-dom';

import Drawer from 'antd/lib/drawer';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import Logout from '@common/react/components/UI/Logout/Logout';
import { transformArrayToList } from '@common/typescript/objects/List';

import { Menu as CustomMenu } from '@app/components/UI/Menu/Menu';
import { User, UserRole } from '@app/objects/User';

interface BurgerMenuProps {
	getPopupContainer?: (node) => HTMLElement;
}

export const menu = [
	{
		path: '/specials',
		name: 'Specials Shop',
		className: 'menu-item_gray',
	},
	{
		path: '/doctors',
		name: 'Schedule Appointment',
		className: 'menu-item_orange',
	},
	{
		path: '/login',
		name: 'Patient Login',
		private: false,
		className: 'menu-item_blue',
	},
	{
		path: '',
		name: 'Search',
		icon: 'search',
		className: 'bold-title',
		private: true,
		children: transformArrayToList([
			{
				path: '/doctors',
				name: 'Doctors',
			},
			{
				path: '/procedures',
				name: 'Procedures',
			},
			{
				path: '/specialties',
				name: 'Specialties',
			},
			{
				path: '/clinics',
				name: 'Clinics',
			},
			{
				path: '/locations',
				name: 'Locations',
			},
		]),
	},
	{
		path: '/doctors',
		name: 'Doctors',
		private: false,
	},
	{
		path: '/procedures',
		name: 'Procedures',
		private: false,
	},
	{
		path: '/specialties',
		name: 'Specialties',
		private: false,
	},
	{
		path: '/clinics',
		name: 'Clinics',
		private: false,
	},
	{
		path: '/locations',
		name: 'Locations',
		private: false,
	},
	{
		path: '/profile',
		name: 'Profile',
		icon: 'user-circle',
		className: 'bold-title',
		private: true,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'home',
		className: 'bold-title',
		private: true,
	},
	{
		path: '/appointment-list',
		name: 'Appointments',
		icon: 'calendar-plus-o',
		className: 'bold-title',
		private: true,
	},
	{
		path: '/analysis-list',
		name: 'Lab. Orders',
		icon: 'flask',
		className: 'bold-title',
		private: true,
	},
	{
		path: '/bills',
		name: 'Bills',
		icon: 'usd',
		className: 'bold-title',
		private: true,
	},
	{
		path: '',
		name: 'Documents',
		icon: 'file-code-o',
		className: 'bold-title',
		private: true,
		children: transformArrayToList([
			{
				path: '/orders',
				name: 'Purchases',
			},
			{
				path: '/document-list',
				name: 'Health Records',
			},
			{
				path: '/questionnaires',
				name: 'Questionnaires',
			},
			{
				path: '/consent-forms',
				name: 'Consent|Medical Documents',
			},
			{
				path: '/instructions',
				name: 'Instructions',
			},
			{
				path: '/prescriptions-list',
				name: 'Prescriptions',
			},
		]),
	},
	{
		path: '',
		admin: true,
		name: 'Admin',
		icon: 'user-secret',
		className: 'bold-title',
		children: transformArrayToList([
			{
				path: '/user-list',
				name: 'Users',
			},
			{
				path: '/shortLink-list',
				name: 'Short Links',
			},
			{
				path: '/authLink-list',
				name: 'Auth Links',
			},
			{
				path: '/user-session-list',
				name: 'Entry Log Journal',
			},
			{
				path: '/page-list',
				name: 'Pages',
			},
			{
				path: '/inquiry-list',
				name: 'Inquiries',
			},
			{
				path: '/email-templates',
				name: 'Email Templates',
			},
			{
				path: '/marketing-emails',
				name: 'Marketing Emails',
			},
			{
				path: '/emailLogs',
				name: 'Email Log',
			},
			{
				path: '/smsLogs',
				name: 'Sms Log',
			},
			{
				path: '/base-hosted-service-list',
				name: 'Hosted Services',
			},
		]),
	},
	{
		path: '',
		name: '',
		private: true,
		node: <li className="menu-item_blue">
			<Logout
				render={(logout) => <a onClick={logout} title="Logout">
					Logout
				</a>}
				clearState
			/>
		</li>,
	},
];

const BurgerMenu: React.FC<BurgerMenuProps> = ({ getPopupContainer }) => {
	const [open, setOpen] = React.useState(false);
	const { getUser } = useApplicationContext();
	const user = getUser<User>();
	const onClose = () => setOpen(false);
	const location = useLocation();

	React.useEffect(() => {
		setOpen(false);
	}, [location.pathname]);

	const resultMenu = React.useMemo(() => {
		return menu.filter((item) => (item.admin
			? user && user.role === UserRole.Admin
			: item.private === undefined || (user && item.private) || (!user && !item.private)));
	}, [user]);

	return <>
		<Drawer
			placement="right"
			onClose={onClose}
			open={open}
			width="max(40vw, 200px)"
			closable={false}
			rootClassName="burger-menu-container"
		>
			<CustomMenu
				items={resultMenu}
				className="burger-menu-list"
				user={user}
			/>
		</Drawer>
		<button className={`menu-burger ${open ? 'menu-burger__open' : ''}`} type="button" onClick={() => setOpen((prev) => !prev)}>
			<span className={`menu-burger__span ${open ? 'menu-burger__span_open' : ''}`} />
		</button>
	</>;
};

export default BurgerMenu;
