import React from 'react';

import { useApplicationContext, subscribe } from '@common/react/components/Core/Application/Application';

import { NotificationAction } from '@common/typescript/objects/NotificationAction';

import { PatientLocation } from '@app/objects/PatientLocation';
import { User } from '@app/objects/User';

const PatientLocationsSetter: React.FC = () => {
	const { getUser, updateUser } = useApplicationContext();
	const user = getUser<User>();

	const handleNotify = (notification) => {
		switch (notification.objectType) {
			case 'UpdatePatientLocation':
				const userData = notification.data as User;
				const patientLocation: PatientLocation = notification.data.value;
				if (user && (user.id === userData.id)) {
					if (notification.action === NotificationAction.Add) {
						const patientLocationIndex = user.patientLocations.findIndex((pl) => pl.id === patientLocation.id);
						if (patientLocationIndex >= 0) {
							const newPatientLocations = user.patientLocations.map((pl, index) => {
								if (index === patientLocationIndex) {
									return { ...pl, ...patientLocation };
								}
								return pl;
							});
							updateUser({ patientLocations: [...newPatientLocations] });
						} else {
							updateUser({ patientLocations: [...user.patientLocations, patientLocation] });
						}
					} else if (notification.action === NotificationAction.Delete) {
						updateUser({ patientLocations: [...user.patientLocations.filter((q) => q.id !== patientLocation.id)] });
					}
				}
				break;
			default:
				break;
		}
	};

	React.useEffect(subscribe(handleNotify), [user?.id]);

	return <></>;
};

export default PatientLocationsSetter;
