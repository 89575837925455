import * as React from 'react';
import {
	NavLink, LinkProps, useLocation, To,
} from 'react-router-dom';

type Props = LinkProps;

const LinkWithPrevLocation: React.FC<Props> = ({
	to, children, ...rest
}) => {
	const location = useLocation();
	let descriptor: To;
	const state = { prevPath: `${location.pathname}${location.search}${location.hash}` };

	if (typeof to === 'string') {
		const [pathname, search] = to.split('?');
		descriptor = {
			pathname,
			search,
		};
	} else {
		descriptor = {
			...to,
		};
	}

	return <NavLink state={state} to={descriptor} {...rest}>{children}</NavLink>;
};

export default LinkWithPrevLocation;
